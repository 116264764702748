import * as AWS from 'aws-sdk'

const docClient = new AWS.DynamoDB.DocumentClient({
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || 'AKIAYSWFO5ENA3IWCLGC',
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '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',
      },
    region: 'us-east-1',
    apiVersion: 'latest',
})

export const updateMeshes = async (tableName, key, Fibroid_meshes) => {
    const params = {
        TableName: tableName,
        Key: {
            patient_id: key,
        },
        UpdateExpression:
            `set #Fibroid_meshes = :v_Fibroid_meshes`,
        ExpressionAttributeNames: {
            '#Fibroid_meshes': 'Fibroid_meshes',
        },
        ExpressionAttributeValues: {
            ':v_Fibroid_meshes': Fibroid_meshes,
        },
        ReturnValues: "ALL_NEW"
    }
    const promise = await docClient.update(params).promise();
    let result = await promise;
    let data = result.Items;
    return data;
};
