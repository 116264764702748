import * as AWS from 'aws-sdk'

const docClient = new AWS.DynamoDB.DocumentClient({
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || 'AKIAYSWFO5ENA3IWCLGC',
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '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',
      },
    region: 'us-east-1',
    apiVersion: 'latest',
})

export const fetchData = async (tableName) => {
    var params = {
        TableName: tableName
    }
    const promise = docClient.scan(params).promise();
    let result = await promise;
    let data = result.Items;
    return data;
};
